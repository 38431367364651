<template>
    <div class="wrap scroll p-child-join">

        <layout-header title="자녀 회원가입" btn-right="logo" btn-left="back"></layout-header>

        <div class="container">
            <div class="content">
                <div class="img-wrap">
                    <img alt="" src="/assets/images/member/img_child_join.png">
                </div>
                <div class="join-form">
                    <form>
                        <fieldset class="join-field">
                            <legend>회원가입 정보</legend>
                            <table>
                                <tr>
                                    <th><label for="email">아이디</label></th>
                                    <td><input id="email" v-model="coAccountVO.coEmailId" class="inp-member" name="" type="text" pattern="[A-Za-z]+"></td>
                                    <td>
                                        <button class="btn-member blue" type="button" @click="isDuplicateUserId">
                                            중복체크
                                        </button>
                                    </td>
                                </tr>
                                <!--                                    <tr>-->
                                <!--                                        <th><label for="password1">비밀번호</label></th>-->
                                <!--                                        <td><input id="password1" v-model="coAccountVO.passwd" class="inp-member"-->
                                <!--                                                   name=""-->
                                <!--                                                   type="text"></td>-->
                                <!--                                        <td>숫자, 영문 혼합 6자리</td>-->
                                <!--                                    </tr>-->
                                <!--                                    <tr>-->
                                <!--                                        <th><label for="password2">비밀번호확인</label></th>-->
                                <!--                                        <td><input id="password2" v-model="coAccountVO.passchk" class="inp-member"-->
                                <!--                                                   name=""-->
                                <!--                                                   type="text"></td>-->
                                <!--                                        <td></td>-->
                                <!--                                    </tr>-->
                                <tr>
                                    <th><label for="name">자녀 이름</label></th>
                                    <td><input id="name" v-model="coAccountVO.nickName" class="inp-member" name=""
                                               type="text"></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th><label for="preschool">기관 검색</label></th>
                                    <td><input id="preschool" v-model="teamName" class="inp-member" disabled
                                               name="" placeholder="유치원 이름" type="text"></td>
                                    <td>
                                        <button class="btn-member modal-open" data-pop="choiceTeam" type="button">
                                            검색
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <th><label class="blind" for="class">반 이름</label></th>
                                    <td><input id="class" v-model="className" class="inp-member" disabled name=""
                                               placeholder="반 이름" type="text"></td>
                                    <td></td>
                                </tr>
                                <!--                                <tr>-->
                                <!--                                    <th><label for="class">보호자<br>핸드폰 번호</label></th>-->
                                <!--                                    <td><input id="class" v-model="coAccountVO.phone" class="inp-member" name=""-->
                                <!--                                               placeholder="숫자만 기입" type="text">-->
                                <!--                                    </td>-->
                                <!--                                    <td>-->
                                <!--                                        <button class="btn-member blue" type="button">인증하기</button>-->
                                <!--                                    </td>-->
                                <!--                                </tr>-->
                                <tr>
                                    <th>캐릭터 선택</th>
                                    <td>
                                        <button class="btn-member purple modal-open" data-pop="choiceCharacter"
                                                type="button">선택하기
                                        </button>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>자녀 성별</th>
                                    <td>
                                        <div class="radio-box">
                                            <input id="male" name="gender" type="radio">
                                            <label for="male">남자</label>
                                        </div>
                                        <div class="radio-box">
                                            <input id="female" name="gender" type="radio">
                                            <label for="female">여자</label>
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th><label for="birth">자녀 출생연도</label></th>
                                    <td>
                                        <input id="birth" v-model="coAccountVO.birthday" class="inp-member" name=""
                                               type="date">
                                    </td>
                                    <td></td>
                                    <!--                                        <th><label>자녀 출생연도</label></th>-->
                                    <!--                                        <select v-model="rateCode" class="result-box rate-box" name="rate">-->
                                    <!--                                            <option v-for="item in ageList" :value="item.code">{{-->
                                    <!--                                                    item.codeName-->
                                    <!--                                                }}-->
                                    <!--                                            </option>-->
                                    <!--                                        </select>-->
                                    <!--                                        <td></td>-->
                                </tr>
                            </table>
                        </fieldset>
                        <fieldset class="agree-field">
                            <legend>회원가입 약관</legend>
                            <div class="check-box">
                                <input id="agree1" v-model="coAccountVO.isMarketing" type="checkbox">
                                <label for="agree1">본인은 성인이며, 만 14세 미만의 아동이 아님을 확인합니다. (필수)</label>
                            </div>
                            <div class="check-box">
                                <input id="agree2" v-model="coAccountVO.isPrivacyPolicy" type="checkbox">
                                <label for="agree2">마샤와 곰의 <span>이용약관</span> 및 <span>개인정보 수집 및 이용</span>에 동의합니다.
                                    (필수)</label>
                            </div>
                        </fieldset>
                        <div class="button-area">
                            <button class="btn-member gray" type="button" @click="$router.go(-1)">취소</button>
                            <button class="btn-member" type="button" @click="profileJoin">확인</button>
                        </div>
                    </form>
                </div>
            </div>

            <div id="choiceCharacter" class="modal modal-common m-character">
                <div class="modal-content">
                    <h3>캐릭터 선택</h3>
                    <form>
                        <ul class="character-list">

                            <template v-for="(item, index) in profileImageArray">
                                <li>
                                    <input :id="'character0' + index" name="character" type="radio">
                                    <label :for="'character0' + index"><img :src="item"
                                                                            alt=""></label>
                                </li>
                            </template>
                            <!--                        <li>-->
                            <!--                            <input id="character01" name="character" type="radio">-->
                            <!--                            <label for="character01"><img alt=""-->
                            <!--                                                          src="/assets/images/common/img_character_01.png"></label>-->
                            <!--                        </li>-->
                            <!--                        <li>-->
                            <!--                            <input id="character02" name="character" type="radio">-->
                            <!--                            <label for="character02"><img alt=""-->
                            <!--                                                          src="/assets/images/common/img_character_02.png"></label>-->
                            <!--                        </li>-->
                            <!--                        <li>-->
                            <!--                            <input id="character03" name="character" type="radio">-->
                            <!--                            <label for="character03"><img alt=""-->
                            <!--                                                          src="/assets/images/common/img_character_03.png"></label>-->
                            <!--                        </li>-->
                            <!--                        <li>-->
                            <!--                            <input id="character04" name="character" type="radio">-->
                            <!--                            <label for="character04"><img alt=""-->
                            <!--                                                          src="/assets/images/common/img_character_04.png"></label>-->
                            <!--                        </li>-->
                            <!--                        <li>-->
                            <!--                            <input id="character05" name="character" type="radio">-->
                            <!--                            <label for="character05"><img alt=""-->
                            <!--                                                          src="/assets/images/common/img_character_05.png"></label>-->
                            <!--                        </li>-->
                            <!--                        <li>-->
                            <!--                            <input id="character06" name="character" type="radio">-->
                            <!--                            <label for="character06"><img alt=""-->
                            <!--                                                          src="/assets/images/common/img_character_06.png"></label>-->
                            <!--                        </li>-->
                            <!--                        <li>-->
                            <!--                            <input id="character07" name="character" type="radio">-->
                            <!--                            <label for="character07"><img alt=""-->
                            <!--                                                          src="/assets/images/common/img_character_07.png"></label>-->
                            <!--                        </li>-->
                            <!--                        <li>-->
                            <!--                            <input id="character08" name="character" type="radio">-->
                            <!--                            <label for="character08"><img alt=""-->
                            <!--                                                          src="/assets/images/common/img_character_08.png"></label>-->
                            <!--                        </li>-->
                        </ul>
                        <div class="button-area">
                            <button class="btn-member gray sm modal-close" type="button">취소</button>
                            <button class="btn-member purple sm" type="button" @click="choiceImage">선택</button>
                        </div>
                    </form>
                </div>
            </div>

            <div id="choiceTeam" class="wrap p-child-preschool modal modal-common">
                <div class="container">
                    <div class="content">
                        <div class="join-form">
                            <form @submit.prevent="teamSearch">
                                <div class="btn btn-back"></div>
                                <div class="guide-text">해당 기관 명을 검색해주세요</div>
                                <div class="search-wrap">
                                    <div class="search-box">
                                        <input id="" v-model="teamSearchText" class="inp-member" name="" type="text">
                                        <button class="btn-member purple sm" type="button" @click="teamSearch">검색
                                        </button>
                                    </div>
                                    <ul class="result-box team-box">
                                        <li v-for="item in teamList" :value="item.teamCode" class="modal-open"
                                            data-pop="choiceClass" @click="teamCode(item)" style="margin: 5px 0 5px 0">{{ item.coName }}
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div id="choiceClass" class="wrap p-child-class modal modal-common">
                <div class="container">
                    <div class="content">
                        <div class="join-form">
                            <form @submit.prevent="classSearch">
                                <div class="guide-text">반을 선택해 주세요.</div>
                                <div class="search-wrap">
                                    <div class="preschool-name">{{ teamName }}</div>
                                    <div class="search-box">
                                        <input id="" v-model="classSearchText" class="inp-member" name="" type="text">
                                        <button class="btn-member purple sm" type="button" @click="classSearch">검색
                                        </button>
                                    </div>
                                    <div class="result-box">
                                        <ul class="class-box">
                                            <li v-for="item in classList" :value="item.code" class='modal-close'
                                                data-pop='choiceClass' @click="classCode" style="margin: 5px 0 5px 0">{{ item.codeName }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader";
import $ from 'jquery'

export default {
    name: "profileJoin",
    components: {LayoutHeader},
    data() {

        const profileImageArray = [
            '/assets/images/common/img_character_01.png',
            '/assets/images/common/img_character_02.png',
            '/assets/images/common/img_character_03.png',
            '/assets/images/common/img_character_04.png',
            '/assets/images/common/img_character_05.png',
            '/assets/images/common/img_character_06.png',
            '/assets/images/common/img_character_07.png',
            '/assets/images/common/img_character_08.png',
        ]

        return {

            coAccountVO: {},

            profileImageArray,

            isChkDuplicateId: false,

            teamList: Object,
            classList: Object,
            ageList: Object,

            teamSearchText: '',
            classSearchText: '',

            teamName: '',
            className: '',

            rateCode: ''

        }
    },
    created() {

        this.requireLoginMainWithoutAlert()
    },
    mounted() {

        $('#choiceTeam').click(function (e) {
            if ($(e.target).parents('.join-form').length < 1) {
                $('.modal').fadeOut();
            }
        });

        $('#choiceClass').click(function (e) {
            if ($(e.target).parents('.join-form').length < 1) {
                $('.modal').fadeOut();
            }
        });

        $('.modal-common')
            .find('.join-form')
            .css('marginLeft', 0)
            .css('zIndex', 998)

        $('.modal-open').on('click', function () {
            var attr = $(this).attr('data-pop');
            $('#' + attr).fadeIn();
        })

        $('.modal-close').on('click', function () {
            $('.modal').fadeOut();
        })

        this.coAccountVO.isParent = false
        this.coAccountVO.parentId = this.getLoginMainId()
        this.ageList = this.getAgeObject()
        this.teamSearch()

    },
    methods: {

        isDuplicateUserId() {

            const self = this

            const id_regs = /^[A-z0-9+]/
            if (!id_regs.test(self.coAccountVO.coEmailId)) {
                this.swalUtils.gritter("영문과 숫자만 가능합니다.", "", "warning", 3000);
                return
            }

            if (this.commonUtils.nullCheck(self.coAccountVO.coEmailId)) {
                this.swalUtils.gritter("아이디를 입력해 주세요.", "", "warning", 3000);
                return
            }

            this.axiosCaller.get(self, self.APIs.CO_PROFILE + "/isDupProfile", {
                coEmailId: self.coAccountVO.coEmailId
            }, (res) => {

                if (res.data.isDupProfile === false) {
                    self.isChkDuplicateId = true
                    this.swalUtils.gritter("확인되었습니다.", "", "warning", 3000);
                } else {
					self.isChkDuplicateId = false
					this.swalUtils.gritter("존재하는 아이디입니다.", "", "warning", 3000);
                }

            })

        },

        getCode(_code, _filter) {

            const self = this
            const _ = window._
            let arr = []

            self.axiosCaller.get(self, self.APIs.COMMON_CODE + "/getCodeTree", {
                code: _code,
            }, (res) => {

                _.forEach(res.data.codeList, (v, i) => {
                    if (self.commonUtils.isContainString(v.codeName, _filter, true) || self.commonUtils.nullCheck(_filter)) {
                        arr.push(res.data.codeList[i])
                    }
                })

            })


            return arr

        },

        getAgeObject() {

            const self = this
            const _ = window._
            let arr = []

            self.axiosCaller.get(self, self.APIs.COMMON_CODE + "/getCodeTree", {
                code: 2002,
            }, (res) => {

                _.forEach(res.data.codeList, (v, i) => {
                    // codeName "n 세" 에서 "세" 를 제외한 숫자만 남김
                    // Object{code: Number(나이)}
                    this.ageList[v.code] = (v.codeName.replace(/[^0-9]/g, "")) * 1
                })
                console.log(this.ageList)

            })


            return arr

        },

        async profileJoin() {

            let code = this.coAccountVO.teamCode + ''

            // if (!this.isChkDuplicateId) {
            //     this.swalUtils.gritter("아이디 중복체크가 되어있지 않습니다.", "", "warning", 3000);
            //     return
            // }

            const self = this

            self.coAccountVO.gender = $('input:radio[id=male]').is(':checked') ? 'm' : $('input:radio[id=female]').is(':checked') ? 'f' : null

            if (self.commonUtils.nullCheckWithLogicalOperator("or",
                self.coAccountVO.coEmailId,
                self.coAccountVO.nickName,
                self.coAccountVO.teamCode,
                self.coAccountVO.profileSrc,
                self.coAccountVO.birthday,
                self.coAccountVO.gender)) {
                this.swalUtils.gritter("정보를 입력해 주세요.", "", "warning", 3000);
                return
            }

            if (!self.commonUtils.parseBoolean(self.coAccountVO.isMarketing) || !self.commonUtils.parseBoolean(self.coAccountVO.isPrivacyPolicy)) {
                this.swalUtils.gritter("약관에 동의해 주세요.", "", "warning", 3000);
                return
            }

            this.axiosCaller.post(self, self.APIs.CO_PROFILE + "/", self.coAccountVO, (res) => {

                const result = res.data

                if (result.status === "ok") {
                    this.coAccountVO = result.coAccountVO

                    self.isChkDuplicateId = false
                    this.swalUtils.gritter("가입되었습니다.", "", "warning", 3000);

                    self.$router.go(-1)

                } else if (result.status === "fail") {
					if (result.msg === "duplicate") {
						this.swalUtils.gritter("중복된 계정입니다.", "", "warning", 3000);
					} else {
						this.swalUtils.gritter("처리 오류", result.msg, "warning", 3000);
					}
                } else if (result.status === "error") {
                    this.swalUtils.gritter("처리 오류", "시스템 오류로 처리되지 않았습니다. 관리자에게 문의하세요", "warning", 3000);
                }
            })
        },

        teamSearch() {

            const self = this
			if (self.teamSearchText == '') return

            self.axiosCaller.get(self, self.APIs.CO_INFO + "/getCoInfo", {
                searchText: self.teamSearchText,
            }, (res) => {

                self.teamList = res.data.coInfo.list

            })

        },

        classSearch() {
            this.classList = this.getCode(this.coAccountVO.teamCode, this.classSearchText)
        },

        teamCode(e) {

            console.log(e)

            $('#choiceClass').fadeIn();
            $('#choiceTeam').fadeOut();

            this.coAccountVO.coCode = e.coCode
            this.coAccountVO.teamCode = e.teamCode
            this.teamName = e.coName

            this.classList = this.getCode(e.teamCode, this.classSearchText)

        },

        classCode(e) {

            $('#choiceClass').fadeOut();

            this.coAccountVO.teamCode = e.target.value
            this.className = e.target.innerText

        },

        choiceImage() {
            const self = this
            const _ = window._

            _.forEach($('[name="character"]'), (v, i) => {
                if ($(v).is(':checked')) {
                    self.coAccountVO.profileSrc = $('[for="character0' + i + '"]').children().attr('src')
                }
            })

            self.commonUtils.localLog(self.coAccountVO.profileSrc)

            $('#choiceCharacter').fadeOut();
        },

    },
    watch: {
        'coAccountVO.coEmailId': function () {
            this.isChkDuplicateId = false
        },
        // 'teamSearch': function () {
        //     this.codeSearch()
        // },
        // 'classSearch': function () {
        //     this.codeSearch()
        // },
        'coAccountVO.birthday': function (curr, prev) {

            const today = new Date();
            const birthDate = new Date(curr);

            let age = today.getFullYear() - birthDate.getFullYear() - 1

            if (age <= 3) {
                age = 3
            } else if (age >= 5) {
                age = 5
            }

            this.coAccountVO.rateCode = this.commonUtils.getKeyByValue(this.ageList, age) * 1

        }
    }

}
</script>

<style scoped>

</style>